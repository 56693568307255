@keyframes highlight {
    0% {
        background-color: white;
    }
    50% {
        background-color: lightgreen;
    }
    100% {
        background-color: white;
    }
}

.highlight {
    animation: highlight 1s ease-out;
}